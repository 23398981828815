import React from "react"
import {Col, Container, Row} from "react-bootstrap"
import Layout from "../components/layout"
import SEO from "../../plugins/gatsby-theme-chegatsby/src/components/seo"
import YouTube from 'react-youtube'
import { StaticImage } from "gatsby-plugin-image"

const AboutPage = () => (
    <Layout pageInfo={{pageName: "index"}}>
        <SEO title="Home" keywords={[`Musica per cerimonie ed eventi`, `Ciro Triassi`, `Agenzia Musicale Salerno`, `Musica per Matrimonio`, `Animazione per Matrimonio`, `Agenzia Eventi e Spettacoli Napoli`, `Agenzia Eventi e Spettacoli Salerno`, `Ciro Triassi Music Live`]}/>

        <Container className="my-4">
            <h5 className="text-center">CIRO TRIASSI MUSIC LIVE</h5>
            <h1 className="mb-4 text-center">CHI SIAMO</h1>
            <Row className="my-4">
                <Col>
                    <StaticImage src="../images/carousel/wedding_awards.jfif" alt="Wedding Awards - Musica per Matrimonio" />
                    <p>Ciro Triassi è un professionista della musica da oltre 15 anni. Oggi opera in collaborazione
                        con un team di validi musicisti professionisti. La famiglia artistica del suo staff è
                        in gradi di esibirsi con grande passione e professionalità, nonché eleganza e simpatia.
                        Ciro Triassi Music Live collabora con note strutture in Campania e non ed è in
                        grado di proporre diverse soluzioni musicali e di spettacolo per il vostro giorno più
                        importante o per una qualsiasi altra occasione.</p>
                    <p><strong>Servizi offerti</strong></p>
                    <p>Oggi più che mai l’evento più importante e suggestivo resta il matrimonio, così come abbiamo
                        ereditato proprio dalla nostra tradizione Campana, cercando nel tempo innovazioni per rendere
                        particolare e insolito quel giorno tanto atteso, pur rimanendo nel vivo della tradizione. E
                        quindi anche il ricevimento ha cambiato stile avvalendosi della giusta musica al momento giusto.
                        A disposizione della vostra festa avrete sempre un ampio ventaglio di proposte musicali che si
                        adattano ai gusti degli ospiti e alle vostre esigenze di intrattenimento. La Ciro Triassi Music
                        Live si occuperà di allestire la scaletta musicale durante tutta la durata della cerimonia in
                        chiesa o in comune, di accompagnare con brani soft il vostro banchetto di nozze e di animare con
                        ritmi coinvolgenti la festa che segue.</p>
                    <p><strong>Soluzioni e Formazioni</strong></p>
                    <p>Ciro Triassi Music Live propone grandi professionisti dell’intrattenimento offrendo diverse
                        formazioni e soluzioni personalizzabili. Le performance prevedono gruppi musicali eterogenei
                        formati da pianisti, vocalist, sassofonista, violinista, piano bar, posteggia napoletana, gruppi
                        musicali e dj set. Per voi potranno anche essere organizzati divertenti spettacoli comici o con
                        artisti di strada. Il repertorio è vasto e ripropone brani italiani, stranieri, classici
                        napoletani, musica soft, musica d’ascolto, musica da ballo tra cui (liscio, balli di gruppo,
                        revival 60-70-80-90, music dance), musica pop, jazz, musica latina, musica leggera e
                        musica classica.</p>
                </Col>
            </Row>
            <Row className="my-4">
                <Col md={6}>
                    <YouTube
					    containerClassName="embed-container" className="embed-container-iframe"
                        videoId="fzMvKqLAwGI"
                        opts={{
							height: null,
							width: null,
                            playerVars: { // https://developers.google.com/youtube/player_parameters
                                autoplay: 0,
                                rel: 0,
                                showinfo: 0,
                                modestbranding: 1,
                            }
                        }}
                    />
                </Col>
                <Col md={6}>
                    <YouTube
					     containerClassName="embed-container" className="embed-container-iframe"
                        videoId="SzNaJ9ZnHnU"
                        opts={{
							height: null,
							width: null,
                            playerVars: { // https://developers.google.com/youtube/player_parameters
                                autoplay: 0,
                                rel: 0,
                                showinfo: 0,
                                modestbranding: 1,
                            }
                        }}
                    />
                </Col>
            </Row>
        </Container>
    </Layout>
)

export default AboutPage
